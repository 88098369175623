import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-467aa4e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-personal" }
const _hoisted_2 = { class: "order-personal__data" }
const _hoisted_3 = {
  style: {"margin-top":"24px"},
  class: "white-box"
}
const _hoisted_4 = { style: {"margin-bottom":"20px"} }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  class: "order-personal__patients",
  ref: "patientsFormWrapper"
}
const _hoisted_7 = { class: "white-box" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "white-box__flex" }
const _hoisted_10 = { class: "order-personal__loading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_step = _resolveComponent("a-step")!
  const _component_a_steps = _resolveComponent("a-steps")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_phone_number = _resolveComponent("phone-number")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_tags = _resolveComponent("tags")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasPermission(_ctx.actions.SHOW_ORDER_STEPS))
      ? (_openBlock(), _createBlock(_component_a_row, {
          key: 0,
          class: "order-personal__steper",
          type: "flex"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, {
              xs: { span: 24 },
              lg: { span: 16 }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_steps, { current: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_step, {
                      title: _ctx.t('ORDER_PERSONAL.STEP_TITLE')
                    }, null, 8 /* PROPS */, ["title"]),
                    _createVNode(_component_a_step, {
                      disabled: "",
                      title: _ctx.t('ORDER_SYMPTOMS.STEP_TITLE')
                    }, null, 8 /* PROPS */, ["title"]),
                    (_ctx.hasPermission(_ctx.actions.SHOW_PRICE) && _ctx.showPaymentStep)
                      ? (_openBlock(), _createBlock(_component_a_step, {
                          key: 0,
                          disabled: "",
                          title: _ctx.t('ORDER_PAYMENT.STEP_TITLE')
                        }, null, 8 /* PROPS */, ["title"]))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_a_row, {
      style: {"margin-top":"32px"},
      type: "flex"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, {
          lg: { span: 19 },
          md: { span: 19 },
          sm: { span: 24 },
          xs: { span: 24 }
        }, {
          default: _withCtx(() => [
            _createElementVNode("h1", null, _toDisplayString(_ctx.t("ORDER_PERSONAL.HEADER")), 1 /* TEXT */),
            _createElementVNode("p", null, _toDisplayString(_ctx.t(`ORDER_PERSONAL.${_ctx.translationType}.SUB_HEADER`)), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_form, {
        ref: "appointmentInfoFormTemplate",
        class: _normalizeClass(
          'order-personal__appointment-info--' +
          _ctx.store.state.order.appointmentType
        ),
        autocomplete: "off",
        model: _ctx.model,
        rules: _ctx.rules
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.t("ORDER_PERSONAL.APPOINTMENT_INFO.HEADER")), 1 /* TEXT */),
            _createVNode(_component_a_row, {
              gutter: [0, { lg: 16, md: 16, sm: 16, xs: 16 }],
              class: "white-box__row order-personal__address"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, {
                  lg: 5,
                  md: 5,
                  sm: 5,
                  xs: 10,
                  class: "white-box__field-name"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("ORDER_PERSONAL.APPOINTMENT_INFO.ADDRESS")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_a_col, {
                  lg: { span: 13, offset: 1 },
                  md: { span: 13, offset: 1 },
                  sm: { span: 12, offset: 2 },
                  xs: { span: 12, offset: 2 },
                  class: "white-box__value"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_row, { gutter: [0, { lg: 16, md: 16, sm: 16, xs: 16 }] }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_col, {
                          lg: { span: 24, offset: 0 },
                          md: { span: 24, offset: 0 },
                          sm: { span: 24, offset: 0 },
                          xs: { span: 24, offset: 0 },
                          class: "white-box__value"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, { class: "order-personal__address-default" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_checkbox, {
                                  checked: _ctx.setDefaultAddress,
                                  "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.setDefaultAddress) = $event))
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", {
                                      innerHTML: 
                          _ctx.t(
                            `ORDER_PERSONAL.APPOINTMENT_INFO.SAVE_AS_DEFAULT_ADDRESS`
                          )
                        
                                    }, null, 8 /* PROPS */, _hoisted_5)
                                  ]),
                                  _: 1 /* STABLE */
                                }, 8 /* PROPS */, ["checked"])
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_a_col, {
                          lg: { span: 8, offset: 0 },
                          md: { span: 24, offset: 0 },
                          sm: { span: 24, offset: 0 },
                          xs: { span: 24, offset: 0 },
                          class: "white-box__value"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, {
                              class: "order-personal__country",
                              name: "address.country"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_input, {
                                  placeholder: 
                        _ctx.t('ORDER_PERSONAL.APPOINTMENT_INFO.COUNTRY')
                      ,
                                  disabled: "",
                                  value: _ctx.model.address.country,
                                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.address.country) = $event))
                                }, null, 8 /* PROPS */, ["placeholder", "value"])
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_a_col, {
                          lg: { span: 8, offset: 1 },
                          md: { span: 24, offset: 0 },
                          sm: { span: 24, offset: 0 },
                          xs: { span: 24, offset: 0 },
                          class: "white-box__value"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, {
                              class: "order-personal__city",
                              name: "address.city",
                              label: " "
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_input, {
                                  placeholder: _ctx.t('ORDER_PERSONAL.APPOINTMENT_INFO.CITY'),
                                  value: _ctx.model.address.city,
                                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.address.city) = $event))
                                }, null, 8 /* PROPS */, ["placeholder", "value"])
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_a_col, {
                          lg: { span: 6, offset: 1 },
                          md: { span: 24, offset: 0 },
                          sm: { span: 24, offset: 0 },
                          xs: { span: 24, offset: 0 },
                          class: "white-box__value"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, {
                              class: "order-personal__post-code",
                              name: "address.postCode",
                              label: " "
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_input, {
                                  placeholder: 
                        _ctx.t('ORDER_PERSONAL.APPOINTMENT_INFO.POST_CODE')
                      ,
                                  value: _ctx.model.address.postCode,
                                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.address.postCode) = $event))
                                }, null, 8 /* PROPS */, ["placeholder", "value"])
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_a_col, {
                          lg: { span: 12, offset: 0 },
                          md: { span: 24, offset: 0 },
                          sm: { span: 24, offset: 0 },
                          xs: { span: 24, offset: 0 },
                          class: "white-box__value"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, {
                              class: "order-personal__street-name",
                              name: "address.streetName",
                              label: " "
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_input, {
                                  placeholder: 
                        _ctx.t('ORDER_PERSONAL.APPOINTMENT_INFO.STREET_NAME')
                      ,
                                  value: _ctx.model.address.streetName,
                                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.address.streetName) = $event))
                                }, null, 8 /* PROPS */, ["placeholder", "value"])
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_a_col, {
                          lg: { span: 5, offset: 1 },
                          md: { span: 24, offset: 0 },
                          sm: { span: 24, offset: 0 },
                          xs: { span: 24, offset: 0 },
                          class: "white-box__value"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, {
                              class: "order-personal__street-number",
                              name: "address.streetNumber",
                              label: " "
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_input, {
                                  placeholder: 
                        _ctx.t('ORDER_PERSONAL.APPOINTMENT_INFO.STREET_NUMBER')
                      ,
                                  value: _ctx.model.address.streetNumber,
                                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.address.streetNumber) = $event))
                                }, null, 8 /* PROPS */, ["placeholder", "value"])
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_a_col, {
                          lg: { span: 5, offset: 1 },
                          md: { span: 24, offset: 0 },
                          sm: { span: 24, offset: 0 },
                          xs: { span: 24, offset: 0 },
                          class: "white-box__value"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, {
                              class: "order-personal__flat-number",
                              name: "address.flatNumber"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_input, {
                                  placeholder: 
                        _ctx.t('ORDER_PERSONAL.APPOINTMENT_INFO.FLAT_NUMBER')
                      ,
                                  value: _ctx.model.address.flatNumber,
                                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.address.flatNumber) = $event))
                                }, null, 8 /* PROPS */, ["placeholder", "value"])
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_a_col, {
                          lg: { span: 24, offset: 0 },
                          md: { span: 24, offset: 0 },
                          sm: { span: 24, offset: 0 },
                          xs: { span: 24, offset: 0 },
                          class: "white-box__value",
                          style: {"display":"none"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, {
                              class: "order-personal__additional-info",
                              name: "address.additionalInfo"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_input, {
                                  placeholder: 
                        _ctx.t('ORDER_PERSONAL.APPOINTMENT_INFO.ADDITIONAL_INFO')
                      ,
                                  value: _ctx.model.address.additionalInfo,
                                  "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.address.additionalInfo) = $event))
                                }, null, 8 /* PROPS */, ["placeholder", "value"])
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_a_row, { class: "white-box__row order-personal__email" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, {
                  lg: 5,
                  md: 5,
                  sm: 5,
                  xs: 10,
                  class: "white-box__field-name"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("ORDER_PERSONAL.APPOINTMENT_INFO.EMAIL")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_a_col, {
                  lg: { span: 13, offset: 1 },
                  md: { span: 13, offset: 1 },
                  sm: { span: 12, offset: 2 },
                  xs: { span: 12, offset: 2 },
                  class: "white-box__value"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "email" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          placeholder: 
                    _ctx.t('ORDER_PERSONAL.APPOINTMENT_INFO.EMAIL_PLACEHOLDER')
                  ,
                          value: _ctx.model.email,
                          "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.email) = $event))
                        }, null, 8 /* PROPS */, ["placeholder", "value"])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_a_row, { class: "white-box__row order-personal__phone-number" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, {
                  lg: 5,
                  md: 5,
                  sm: 5,
                  xs: 10,
                  class: "white-box__field-name"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("ORDER_PERSONAL.APPOINTMENT_INFO.PHONE_NUMBER")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_a_col, {
                  lg: { span: 13, offset: 1 },
                  md: { span: 13, offset: 1 },
                  sm: { span: 12, offset: 2 },
                  xs: { span: 12, offset: 2 },
                  class: "white-box__value"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "phoneNumber" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_phone_number, {
                          placeholder: _ctx.t('PHONE_NUMBER.NUMBER'),
                          onChange: _ctx.onPhoneNumberChange,
                          value: _ctx.model.phoneNumber,
                          "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.phoneNumber) = $event))
                        }, null, 8 /* PROPS */, ["placeholder", "onChange", "value"])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["class", "model", "rules"]),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.patients, (patient, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "order-personal__patient",
            key: index,
            style: {"margin-top":"24px"}
          }, [
            _createVNode(_component_a_form, {
              ref: 
              (el) => {
                if (el) _ctx.patientsFormTemplate[index] = el;
              }
            ,
              model: patient.model,
              rules: patient.rules,
              autocomplete: "off"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  (_ctx.patients.length > 1)
                    ? (_openBlock(), _createElementBlock("h2", _hoisted_8, _toDisplayString(_ctx.t("ORDER_PERSONAL.PATIENT.HEADER", { index: index + 1 })), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true),
                  _createVNode(_component_a_row, { class: "white-box__row order-personal__name" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, {
                        lg: 5,
                        md: 5,
                        sm: 5,
                        xs: 10,
                        class: "white-box__field-name"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("ORDER_PERSONAL.PATIENT.FULL_NAME")), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_a_col, {
                        lg: { span: 13, offset: 1 },
                        md: { span: 13, offset: 1 },
                        sm: { span: 16, offset: 2 },
                        xs: { span: 12, offset: 2 },
                        class: "white-box__value"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_row, { gutter: [0, { lg: 16, md: 16, sm: 16, xs: 16 }] }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, {
                                lg: { span: 11, offset: 0 },
                                md: { span: 24, offset: 0 },
                                sm: { span: 24, offset: 0 },
                                xs: { span: 24, offset: 0 }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_form_item, { name: "firstName" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_input, {
                                        class: "order-personal__first-name",
                                        value: patient.model.firstName,
                                        "onUpdate:value": ($event: any) => ((patient.model.firstName) = $event),
                                        placeholder: 
                            _ctx.t('ORDER_PERSONAL.PATIENT.FIRST_NAME_PLACEHOLDER')
                          ,
                                        disabled: _ctx.forNfz
                                      }, null, 8 /* PROPS */, ["value", "onUpdate:value", "placeholder", "disabled"])
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1024 /* DYNAMIC_SLOTS */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */),
                              _createVNode(_component_a_col, {
                                lg: { span: 11, offset: 2 },
                                md: { span: 24, offset: 0 },
                                sm: { span: 24, offset: 0 },
                                xs: { span: 24, offset: 0 }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_form_item, { name: "lastName" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_input, {
                                        class: "order-personal__last-name",
                                        value: patient.model.lastName,
                                        "onUpdate:value": ($event: any) => ((patient.model.lastName) = $event),
                                        placeholder: 
                            _ctx.t('ORDER_PERSONAL.PATIENT.LAST_NAME_PLACEHOLDER')
                          ,
                                        disabled: _ctx.forNfz
                                      }, null, 8 /* PROPS */, ["value", "onUpdate:value", "placeholder", "disabled"])
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1024 /* DYNAMIC_SLOTS */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */),
                  _createVNode(_component_a_row, {
                    class: "white-box__row order-personal__has-id",
                    style: {"display":"none"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, {
                        lg: 5,
                        md: 5,
                        sm: 5,
                        xs: 10,
                        class: "white-box__field-name"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("ORDER_PERSONAL.PATIENT.HAS_ID")), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_a_col, {
                        lg: { span: 13, offset: 1 },
                        md: { span: 13, offset: 1 },
                        sm: { span: 16, offset: 2 },
                        xs: { span: 12, offset: 2 },
                        class: "white-box__value"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "idType" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_9, [
                                _createVNode(_component_tags, {
                                  mode: "single",
                                  "allow-empty": false,
                                  "empty-text": _ctx.t('NO_DATA'),
                                  value: patient.idType,
                                  "onUpdate:value": ($event: any) => ((patient.idType) = $event),
                                  data: _ctx.idTypeOptions,
                                  onChange: ($event: any) => (_ctx.onIdTypeChange(patient, $event))
                                }, null, 8 /* PROPS */, ["empty-text", "value", "onUpdate:value", "data", "onChange"])
                              ])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */),
                  _withDirectives(_createVNode(_component_a_row, { class: "white-box__row order-personal__pesel" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, {
                        lg: 5,
                        md: 5,
                        sm: 5,
                        xs: 10,
                        class: "white-box__field-name"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("ORDER_PERSONAL.PATIENT.PESEL")), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_a_col, {
                        lg: { span: 13, offset: 1 },
                        md: { span: 13, offset: 1 },
                        sm: { span: 16, offset: 2 },
                        xs: { span: 12, offset: 2 },
                        class: "white-box__value"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "identificationDocument.number" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                disabled: !_ctx.hasPermission('ORDER_CHANGE_ID') || _ctx.forNfz,
                                value: 
                        patient.model.identificationDocument.number
                      ,
                                "onUpdate:value": ($event: any) => ((
                        patient.model.identificationDocument.number
                      ) = $event),
                                onChange: ($event: any) => (
                        _ctx.onPeselChange(
                          patient,
                          patient.model.identificationDocument.number
                        )
                      ),
                                placeholder: 
                        _ctx.t('ORDER_PERSONAL.PATIENT.PESEL_PLACEHOLDER')
                      
                              }, null, 8 /* PROPS */, ["disabled", "value", "onUpdate:value", "onChange", "placeholder"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1536 /* NEED_PATCH, DYNAMIC_SLOTS */), [
                    [_vShow, patient.idType.has('PESEL')]
                  ]),
                  _withDirectives(_createVNode(_component_a_row, { class: "white-box__row order-personal__id" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, {
                        lg: 5,
                        md: 5,
                        sm: 5,
                        xs: 10,
                        class: "white-box__field-name"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("ORDER_PERSONAL.PATIENT.OTHER_ID")), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_a_col, {
                        lg: { span: 13, offset: 1 },
                        md: { span: 13, offset: 1 },
                        sm: { span: 16, offset: 2 },
                        xs: { span: 12, offset: 2 },
                        class: "white-box__value"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_row, { gutter: [0, { lg: 16, md: 16, sm: 16, xs: 16 }] }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, {
                                lg: { span: 8, offset: 0 },
                                md: { span: 24, offset: 0 },
                                sm: { span: 24, offset: 0 },
                                xs: { span: 24, offset: 0 }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select, {
                                    class: "order-personal__id-type",
                                    value: 
                          patient.model.identificationDocument.type
                        ,
                                    "onUpdate:value": ($event: any) => ((
                          patient.model.identificationDocument.type
                        ) = $event),
                                    "not-found-content": _ctx.t('NO_DATA')
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.identificationDocumentTypes, (identificationDocumentType) => {
                                        return (_openBlock(), _createBlock(_component_a_select_option, {
                                          value: identificationDocumentType.type,
                                          key: identificationDocumentType.type
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(identificationDocumentType.label), 1 /* TEXT */)
                                          ]),
                                          _: 2 /* DYNAMIC */
                                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                                      }), 128 /* KEYED_FRAGMENT */))
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "onUpdate:value", "not-found-content"])
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */),
                              _createVNode(_component_a_col, {
                                lg: { span: 15, offset: 1 },
                                md: { span: 24, offset: 0 },
                                sm: { span: 24, offset: 0 },
                                xs: { span: 24, offset: 0 }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_form_item, { name: "identificationDocument.number" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_input, {
                                        class: "order-personal__id-number",
                                        value: 
                            patient.model.identificationDocument.number
                          ,
                                        "onUpdate:value": ($event: any) => ((
                            patient.model.identificationDocument.number
                          ) = $event),
                                        placeholder: 
                            _ctx.t('ORDER_PERSONAL.PATIENT.OTHER_ID_PLACEHOLDER')
                          
                                      }, null, 8 /* PROPS */, ["value", "onUpdate:value", "placeholder"])
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1024 /* DYNAMIC_SLOTS */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1536 /* NEED_PATCH, DYNAMIC_SLOTS */), [
                    [_vShow, patient.idType.has('OTHER')]
                  ]),
                  _createVNode(_component_a_row, { class: "white-box__row order-personal__birthdate" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, {
                        lg: 5,
                        md: 5,
                        sm: 5,
                        xs: 10,
                        class: "white-box__field-name"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("ORDER_PERSONAL.PATIENT.BIRTHDATE")), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_a_col, {
                        lg: { span: 13, offset: 1 },
                        md: { span: 13, offset: 1 },
                        sm: { span: 16, offset: 2 },
                        xs: { span: 12, offset: 2 },
                        class: "white-box__value"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "birthdate" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_date_picker, {
                                disabled: patient.idType.has('PESEL'),
                                value: patient.model.birthdate,
                                "onUpdate:value": ($event: any) => ((patient.model.birthdate) = $event),
                                placeholder: "DD.MM.RRRR",
                                "allow-clear": false,
                                style: {"width":"100%"},
                                format: "DD.MM.YYYY"
                              }, null, 8 /* PROPS */, ["disabled", "value", "onUpdate:value"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */)
                ])
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["model", "rules"])
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ], 512 /* NEED_PATCH */)
    ], 512 /* NEED_PATCH */), [
      [_vShow, !_ctx.loading]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_10, [
      _createVNode(_component_a_spin, { size: "large" })
    ], 512 /* NEED_PATCH */), [
      [_vShow, _ctx.loading]
    ]),
    _createVNode(_component_a_row, {
      style: {"margin":"64px 0 42px 0"},
      class: "order-personal__footer",
      type: "flex"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, {
          lg: { span: 16 },
          md: { span: 20 },
          sm: { span: 22 },
          xs: { span: 24 }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              onClick: _ctx.submit,
              disabled: !_ctx.valid[0] || !_ctx.valid[1],
              type: "primary",
              class: "order-personal__submit"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("ORDER_PERSONAL.SUBMIT")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick", "disabled"]),
            _createVNode(_component_router_link, { to: "/make-appointment" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, { class: "order-personal__cancel" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("ORDER_PERSONAL.CANCEL")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}